<template>
  <div id="app" class="site grid-container container hfeed" style="height: auto !important">
    <HeadNav selectedLanguage="en" page="download" />

    <div id="content" class="site-content" style="height: auto !important">
      <article id="article" class="center" style="height: auto !important">
        <section id="downloadpage" style="height: auto !important">
          <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />

          <div id="download" class="wp-block-buttons aligncenter"
            style="width: 100%; display: block; text-align: center" @click="downloadfrom('bigbtn')">
            <div class="wp-block-button">
              <div class="godxthemes-btn wp-block-button__linkvk ripple">
                <i class="material-icons">
                  <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" fill="#fff">
                    <path
                      d="m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z" />
                  </svg>
                </i>
                Download Now
              </div>
            </div>
          </div>
          <div class="wpx5">
            <p class="has-text-align-center" style="height: auto !important">
              If the download doesn't start in a few seconds,
              <span id="downloadapk" @click="download()">
                click here
              </span>
            </p>
          </div>
          <center>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button">
                <router-link :to="{name:`${selectedLanguage}-gb`}" class="wp-block-button__linkvk clickable">
                  <i class="material-icons">
                    <svg class="icon" fill="#fff" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24" width="24px" height="24px">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M14.91 6.71c-.39-.39-1.02-.39-1.41 0L8.91 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L11.03 12l3.88-3.88c.38-.39.38-1.03 0-1.41z" />
                    </svg>
                  </i>
                  Back to The Post
                </router-link>
              </div>
            </div>
          </center>

          <a id="abc" :href="apk" target="_self" download />
          <div class="wpxads">
            <center>
              <p>
                WhatsApp Mods GBWA Pro is a Non-Official WhatsApp Version That Allows You To Hide
                Double Blue Checks Or Last Connection Time And Many
                More Features. Material Designed Mod, That Provides Tons Of Features Including
                Customization, Themes, Changing Styles, App Lock,
                Conversation Locks, Privacy Mods, And Many More.
              </p>
              <br>
              <div class="wp-block-buttons is-content-justification-center">
                <div class="wp-block-button">
                  <a href="https://t.me/gb_download" target="_blank"
                    class="dlbtn wp-block-button__linkvk clickable">
                    Join On Telegram
                  </a>
                </div>
              </div>
              <br>
              <p class="btns">
                <a href="https://www.gbpro.download/yowhatsapp-apk-download-n">YOWhatsApp</a>
                <a href="https://www.gbpro.download/fmwhatsapp-plus-apk-download-n">FMWhatsApp</a>
                <a href="https://www.gbpro.download/whatsapp-plus-apk-download-n">WhatsApp Plus</a>
                <a href="https://waproapk.com/gbwhatsapp-for-iphone">GB WhatsApp For iPhone</a>
              </p>
            </center>
          </div>

          <div class="guide">
            <h2>Guide to Download GB WhatsApp Updated 2025</h2>
            <table class="download-table">
              <tr>
                <th>Step 1</th>
                <td>Visit our site and click the download button. It will direct you to the GB WhatsApp download page. Then, click Download Now.</td>
              </tr>
              <tr>
                <th>Step 2</th>
                <td>Enable install unknown source of your phone, then you can download it.</td>
              </tr>
              <tr>
                <th>Step 3</th>
                <td>Follow the on screen guide to install <a href="https://gbapks.com.pk/" target="_blank">GB WhatsApp 2025</a>. You can give permission to this safe package.</td>
              </tr>
              <tr>
                <th>Step 4</th>
                <td>Launch the app and verify your account.</td>
              </tr>
            </table>
            <p>
              Also read: <router-link :to="{name:`${selectedLanguage}-blog6`}">Top 10 WhatsApp Mods For Android 2024 (Anti-Ban)</router-link>
            </p>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/download.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Download',
  data () {
    return {
      pageName: 'download',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
      selectedLanguage: 'en',
    };
  },
  components: {
    HeadNav,
  },
  mounted () {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom (reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    getAPK () {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk (type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download () {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>
